import React from 'react';
import CheckmarkIcon from 'components/icon/checkmark-icon';
import WarningIcon from 'components/icon/warning-icon';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';
import { buildClassName } from 'utils/build-class-name';

interface Props {
  errors?: string;
  success?: string;
  warning?: string;
  className?: string;
  isLargeError?: boolean;
}

export default function FormMessages({ errors, success, warning, className, isLargeError }: Props) {
  return (
    <div className={buildClassName(styles.component, className)}>
      {errors && <p className={buildClassName(styles.error, isLargeError && styles.large)} data-testid={testIds.formMessageError}><WarningIcon /> {errors}</p>}
      {success && <p className={buildClassName(styles.success, isLargeError && styles.large)}><CheckmarkIcon /> {success}</p>}
      {warning && <p className={buildClassName(styles.warning, isLargeError && styles.large)} data-testid={testIds.formWarning}><WarningIcon /> {warning}</p>}
    </div>
  );
}
