/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useUserContext } from 'contexts/user';
import { useThemeContext } from 'contexts';
import TextField from 'components/control/text-field';
import TextArea from 'components/control/text-area';
import Checkbox from 'components/control/checkbox';
import Button from 'components/control/button';
import { trackConnectionRequest, trackEvent } from 'utils/google-tag-manager';
import PlusIcon from 'components/icon/plus-icon';
import { buildClassName } from 'utils/build-class-name';
import SuccessMessage from './success-message';
import styles from './style.module.scss';
import SignUpCta from '../sign-up-cta';
import captureFubUserWithConsent from 'pages/api/consent-disclaimers/consentApiClient';
import { getFormTitle } from './connection-request-utils';
import { listingPageModalIds, loginRegistrationIds } from 'constants/test-constants';
import { CountryCodeList } from 'types/countries';
import { areDisclaimersChecked } from 'utils/consent';
import { useConsentLabels } from 'hooks/use-consent-labels';
import PhoneCTA from './call-cta';
import Snackbar from 'components/snackbar';
import { MLML_AGENT_COOKIE_NAME } from 'constants/cookies';
import Cookies from 'js-cookie';
import { GTM_CLICK_ADDRESS_PAGE_MAIN_PVR_CTA, GTM_CLICK_ADDRESS_PAGE_PHOTO_GALLERY_PVR_CTA } from 'constants/events';
import { createConnectionRequest } from 'data/connection-request';
import { ThemeNames } from 'types/themes';
import FormMessages from 'components/form/messages';

import type Listing from 'data/listing';

export type ListingInfoType = Pick<Listing, 'isAvailable' | 'streetAddress' | 'id' | 'isOffMarket' | 'isRental' | 'addressPathWithFallback'>;

export interface ConnectionRequestProps {
  /** The object containing information about the property. */
  listing: ListingInfoType;

  /** Determines whether the component is being displayed in a modal. Default is *false* */
  isInModal?: boolean;

  /**  Determines whether to display the 'Take a Tour' or 'Request Info' button label. Default is *true*  */
  isViewingRequest?: boolean;

  /**  Determines if the component is on an address page. Default is *false*  */
  isAddressPage?: boolean;

  /**  Determines if the component is on the photo gallery. Default is *false*  */
  isOnPhotoGallery?: boolean;

  /**  Custom button text to display. Default is *undefined*  */
  customButtonText?: string;

  prefillData?: Record<string, any>;
}

export default function ConnectionRequest({ listing, isInModal, isViewingRequest = true, isAddressPage = false, isOnPhotoGallery = false, customButtonText, prefillData = {}}: ConnectionRequestProps) {
  const { isAuthenticated, user, saveAndPersistUser, userLocation, hasAcceptedConnRequestDisclaimers, handleConnRequestDisclaimers } = useUserContext();
  const { themeName } = useThemeContext();
  const { register, handleSubmit, formState: { errors }, setError, clearErrors, reset, setValue } = useForm();
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [commentsPlaceholder, setCommentsPlaceholder] = useState('Message');
  const [requestViewing, setRequestViewing] = useState(prefillData.requestViewing ?? isViewingRequest);
  const [termsPrivacy, setTermsPrivacy] = useState(hasAcceptedConnRequestDisclaimers);
  const [hasConsent, setHasConsent] = useState(hasAcceptedConnRequestDisclaimers);
  const [isShowingAdditionalComments, setIsShowingAdditionalComments] = useState(prefillData.isShowingAdditionalComments ?? isInModal);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
  const [eventAction, setEventAction] = useState('');
  const [userActivity, setUserActivity] = useState<string[]>([]);
  const [agreeToNewsletter, setAgreeToNewsletter] = useState(false);
  const userActivityRef = useRef<string[]>();
  const successfullySubmittedRef = useRef(false);
  const isUserOutsideCanada = userLocation?.countryCode !== CountryCodeList.CANADA;
  const hasDisclaimerErrors = isUserOutsideCanada && (!!errors.termsPrivacy || !!errors.hasConsent);

  const { isAvailable, streetAddress, id: listingId, isOffMarket, isRental, addressPathWithFallback } = listing;

  const takeTour = 'Take a Tour';

  const isExpTheme = themeName !== ThemeNames.ZOOCASA;
  const showsMLMLCreate = themeName && themeName === ThemeNames.EXP_REALTY_US && !!Cookies.get(MLML_AGENT_COOKIE_NAME);
  const showsMLML = themeName && themeName === ThemeNames.EXP_REALTY_US && !showsMLMLCreate;
  const buttonText = customButtonText ? customButtonText : isOffMarket ? 'Free Consultation' : requestViewing ? takeTour : 'Request Info';

  const { privacyPolicyConsentLabel, fubConsentDisclaimerText, marketingCommunicationsConsentLabel } = useConsentLabels(buttonText);

  const getCopyLanguage = () => {
    if (isOffMarket) {
      return 'Connect with a local agent for a free home estimate and information about sales in the area.';
    } else {
      return `Complete the form and we'll be in touch. ${!isAuthenticated ? 'No login necessary.' : ''}`;
    }
  };

  const sendRequest = async (values: Record<string, unknown>) => {
    if (!areDisclaimersChecked({ isUserOutsideCanada, termsPrivacy, hasConsent, setError, handleConnRequestDisclaimers })) {
      return;
    }

    const checkFormValues = () => {
      if (!values.firstName || !values.lastName || !values.email || !values.phone) {
        setFormErrorMessage('Please fill in all required fields.');
        return; // stop and do NOT proceed
      }
    };
    checkFormValues();

    const trackAllEvents = () => {
      if (isAddressPage) {
        if (isOnPhotoGallery) {
          trackEvent(GTM_CLICK_ADDRESS_PAGE_PHOTO_GALLERY_PVR_CTA);
        }
        else {
          trackEvent(GTM_CLICK_ADDRESS_PAGE_MAIN_PVR_CTA);
        }
        if (!isInModal && buttonText == takeTour) trackEvent('XpA_AddressTakeATourButClk');
      }
      trackEvent(eventAction);
      if (isInModal) {
        trackEvent('Mobile_PVR_Submit');
      }
      if (showsMLML) {
        trackEvent('MLML_Lead');
      }
      trackEvent('connection-request-submission'); // Temporarily added for Smart Chat Experiment
      !isInModal && trackEvent('desktop-submit-pvr');
      isInModal && trackEvent('mobile-submit-pvr');
    };

    const sourceComments = listing && !isAvailable ? 'How much did this property sell for?' : values.sourceComments;
    const properties = {
      ...values,
      listingId,
      streetAddress,
      requestViewing,
      sourceComments,
      newsletter: agreeToNewsletter,
      userCountry: userLocation?.countryCode,
      theme: themeName,
      addressPath: addressPathWithFallback,
    };

    createConnectionRequest(properties)
      .then(() => {
        setSuccessfullySubmitted(true);
        trackConnectionRequest();
        reset();
        trackAllEvents();
        if (agreeToNewsletter) {
          trackEvent(`newsletter-signup-${eventAction}`);
          user && saveAndPersistUser({ ...user, newsletter: true });
        }
        if (isUserOutsideCanada) {
          // Send user data to fub and store ip
          captureFubUserWithConsent({
            firstName: user?.firstName || values.firstName as string,
            lastName: user?.lastName || values.lastName as string,
            email: user?.email || values.email as string,
            phone: user?.homePhone || values.phone as string,
            userIp: userLocation?.ip || '',
            source: themeName,
            listingId: listingId,
            listingUrl: addressPathWithFallback,
            hasConsented: true,
            consentDisclaimerText: fubConsentDisclaimerText,
            countryCode: userLocation?.countryCode,
            createdAt: (new Date()).toISOString(),
          });
        }
      }).catch(({ errors: serverErrors }) => {
        serverErrors.forEach(({ source }) => {
          const pointer = source?.pointer; // e.g. "/data/attributes/email"
          if (pointer === '/data/attributes/email') {
            setFormErrorMessage('Invalid email');
          } else if (pointer === '/data/attributes/home-phone') {
            setFormErrorMessage('Invalid phone number');
          } else {
            setFormErrorMessage('Something went wrong');
          }
        });
      });
  };

  const requestViewingOfActiveListing = listing && isAvailable && requestViewing;

  const getRequestViewingLabel = () => {
    return isAvailable ? 'I want to view this property' : 'I want to view properties like this one';
  };

  const generateCommentsPlaceholder = () => {
    let commentsPlaceholder = '';
    if (listing) {
      if (requestViewingOfActiveListing) {
        commentsPlaceholder = 'When would you like to see this property?';
      } else {
        commentsPlaceholder = 'What would you like to know about this property?';
      }
    }
    setCommentsPlaceholder(commentsPlaceholder + ' (optional)');
  };

  useEffect(() => {
    generateCommentsPlaceholder();
    if (prefillData.sourceComments) {
      setValue('sourceComments', prefillData.sourceComments); // Set the prefill value and make the field editable
    }
  }, [listing, requestViewingOfActiveListing, prefillData.sourceComments]);

  useEffect(() => {
    let eventAction = `property_${requestViewing ? 'viewing': 'info'}_request`;
    if (isRental) {
      eventAction += '_rent';
    }
    setEventAction(eventAction);
  }, [requestViewing, listing]);

  const captureFormEvents = (event: React.FormEvent<HTMLFormElement>) => {
    event.persist();
    const name = (event.target as HTMLInputElement).name;
    if (name && name !== 'requestViewing') {
      setUserActivity(prev => [...prev, name]);
    }
  };

  const getFilteredActivityList = () => {
    if (userActivityRef.current) {
      return userActivityRef.current.reduce((accum: string[], activity: string) => {
        if (accum[accum.length - 1] !== activity) {
          accum.push(activity);
        }
        return accum;
      }, []);
    }
  };

  useEffect(() => {
    return () => {
      const activity = getFilteredActivityList()?.join(' > ');
      if (!user && activity && !successfullySubmittedRef.current) {
        // Track drop off point of users abandoning form during filling the form out
        trackEvent(`activity: ${activity}`, 'connectionRequestFormAbandonment');
      } else if (!user && isInModal && !activity && !successfullySubmittedRef.current) {
        // Track a user not interacting with PVR and closing it on mobile
        trackEvent('abandon-connection-request-modal', 'connectionRequestFormAbandonment');
      }
    };
  }, []);

  // Used to access state during component unmounting
  useEffect(() => {
    successfullySubmittedRef.current = successfullySubmitted;
  }, [successfullySubmitted]);

  useEffect(() => {
    userActivityRef.current = userActivity;
  }, [userActivity]);

  const formTitle = getFormTitle(isOffMarket);

  return (
    <>
      <form
        onChange={captureFormEvents}
        onSubmit={e =>{
          clearErrors(); // clear manually before submit as we use custom error messages
          handleSubmit(sendRequest)(e);
        }}
        className={buildClassName(styles.component, isInModal && styles.modal, isExpTheme && styles['exp-theme'])}
        id={eventAction}
        data-testid={listingPageModalIds.connectionRequestForm}
      >
        {!successfullySubmitted ?
          <>
            <h3 data-testid={listingPageModalIds.connectionRequestFormTitle}>
              {formTitle}
            </h3>
            <p className={`${styles.note} ${styles.doubleLineHeight}`}>
              {getCopyLanguage()}
            </p>
            <FormMessages errors={formErrorMessage} isLargeError />
            <TextField placeholder={'First Name'} {...register('firstName', { value: user?.firstName })} value={user?.firstName} isHidden={isAuthenticated && !errors.firstName} testId={listingPageModalIds.requestfirstname} />
            <TextField placeholder={'Last Name'} {...register('lastName', { value: user?.lastName })} testId={listingPageModalIds.requestlastname} value={user?.lastName} isHidden={isAuthenticated && !errors.lastName} />
            <TextField placeholder={'Email'} {...register('email', { value: user?.email })} testId={listingPageModalIds.requestemail} value={user?.email} />
            <TextField placeholder={'Phone Number'} {...register('phone', { value: user?.homePhone })} testId={listingPageModalIds.requestphonenumber} value={user?.homePhone} isHidden={isAuthenticated && !errors.homePhone} />
            {!isShowingAdditionalComments && <div onClick={() => setIsShowingAdditionalComments(true)} className={styles['add-comment']} data-testid={listingPageModalIds.requestAddComment}><PlusIcon /> Add a Comment</div>}
            {isShowingAdditionalComments &&
              <div className={styles['comment-box']}>
                <TextArea
                  placeholder={commentsPlaceholder}
                  {...register('sourceComments')}
                  testId={listingPageModalIds.requestComments}
                  defaultValue={prefillData.sourceComments || ''}
                />
              </div>
            }
            {listing && !isOffMarket &&
              <div className={styles['checkbox-container']}>
                <Checkbox
                  label={getRequestViewingLabel()}
                  name="requestViewing"
                  value={requestViewing}
                  onValueChange={setRequestViewing}
                  checked={requestViewing}
                  testId={listingPageModalIds.requestViewingCheckbox}
                />
              </div>
            }
            {(!user?.newsletter && !isExpTheme) &&
              <div className={styles['checkbox-container']}>
                <Checkbox
                  label={<span className={styles.doubleLineHeight}>Yes, I&apos;d like to receive free weekly market insights and buying and selling tips. Unsubscribe at any time.</span>}
                  name='agreeToNewsletter'
                  value={agreeToNewsletter}
                  onValueChange={setAgreeToNewsletter}
                  testId={loginRegistrationIds.createAccountNewslettersCheckbox}
                />
              </div>
            }
            {hasDisclaimerErrors && <p className={styles['disclaimer-error']}>Please check the boxes below to continue, or call us at 1-844-683-4664</p>}
            <div className={buildClassName(styles['submit-button-container'], isExpTheme && styles['submit-button-exp'])}>
              <Button label={buttonText} type="submit" theme={customButtonText == 'Learn More' ? 'secondary' : 'primary'} testId={listingPageModalIds.preconSubmitRequestButton} />
            </div>
            {isUserOutsideCanada &&
              <div className={styles.disclaimers}>
                <div className={styles['checkbox-container']}>
                  <Checkbox
                    label={privacyPolicyConsentLabel}
                    name="termsPrivacy"
                    value={termsPrivacy} 
                    onValueChange={setTermsPrivacy}
                    hasError={!!errors.termsPrivacy}
                    testId={loginRegistrationIds.privacyPolicyConsentCheckbox}
                  />
                </div>
                <div className={styles['checkbox-container']}>
                  <Checkbox
                    label={marketingCommunicationsConsentLabel}
                    name="hasConsent"
                    value={hasConsent}
                    onValueChange={setHasConsent}
                    hasError={!!errors.hasConsent}
                    testId={loginRegistrationIds.marketingCommunicationsConsentCheckbox}
                  />
                </div>
              </div>
            }
          </>
          :
          <SuccessMessage isInModal={isInModal} isAuthenticated={isAuthenticated} listing={listing} />
        }
        {!isInModal && !isUserOutsideCanada && !successfullySubmitted && <PhoneCTA />}
      </form>
      {successfullySubmitted && !isAuthenticated && <SignUpCta />}
      <Snackbar />
    </>
  );
}
